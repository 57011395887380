// extracted by mini-css-extract-plugin
export var whitepapersContainer = "styles-module--whitepapersContainer--OC6yA";
export var whitepapersContainerPage = "styles-module--whitepapersContainer-page--BSnGH";
export var whitepapersContainerPageTitle = "styles-module--whitepapersContainer-page-title--FRgg1";
export var whitepapersContainerPageTitleH1 = "styles-module--whitepapersContainer-page-title-h1--FeFo-";
export var whitepapersContainerPageCarts = "styles-module--whitepapersContainer-page-carts--YpDnc";
export var whitepapersContainerPageCartsCart = "styles-module--whitepapersContainer-page-carts-cart--WsiKf";
export var whitepapersContainerPageCartsCartPicture = "styles-module--whitepapersContainer-page-carts-cart-picture--WtSjw";
export var whitepapersContainerPageCartsCartContent = "styles-module--whitepapersContainer-page-carts-cart-content--lRjpD";
export var whitepapersContainerPageCartsCartContentText = "styles-module--whitepapersContainer-page-carts-cart-content-text--mLwGN";
export var whitepapersContainerPageCartsCartContentButton = "styles-module--whitepapersContainer-page-carts-cart-content-button--if2IP";