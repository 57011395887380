import React from 'react'

import one from '../../../assets/images/png/whitepapersPage/1.png'
import two from '../../../assets/images/png/whitepapersPage/2.png'
import file1 from '../../../assets/files/Ecology.pdf'
import file2 from '../../../assets/files/Login to It in just 60 minutes.pdf'
import * as styles from './styles.module.scss'

const oneCart = [
  {
    id: 199999494949,
    picture: one,
    title: 'Say No to Plastic: ',
    text: 'How to start taking care of the Planet?',
    button: 'Download',
    link: file1,
  },
  {
    id: 2934203533333,
    picture: two,
    title: 'How to start?',
    text: 'Login to It in just 60 minutes',
    button: 'Download',
    link: file2,
  },
]

const WhitepapersBlock = () => (
  <div className={styles.whitepapersContainer}>
    <div className={styles.whitepapersContainerPage}>
      <div className="container">
        <div className={styles.whitepapersContainerPageTitle}>
          <h1 className={styles.whitepapersContainerPageTitleH1}>
            Whitepapers
          </h1>
        </div>
        {oneCart && oneCart.length > 0 ? (
          <div className={styles.whitepapersContainerPageCarts}>
            {oneCart?.map((el) => (
              <div key={el.id} className={styles.whitepapersContainerPageCartsCart}>
                <div
                  className={styles.whitepapersContainerPageCartsCartPicture}
                >
                  <img src={el.picture} alt="say no to plastic" />
                </div>
                <div
                  className={styles.whitepapersContainerPageCartsCartContent}
                >
                  <div
                    className={
                      styles.whitepapersContainerPageCartsCartContentText
                    }
                  >
                    <p>{el.title}</p>
                    <p>{el.text}</p>
                  </div>
                  <div
                    className={
                      styles.whitepapersContainerPageCartsCartContentButton
                    }
                  >
                    <a download href={el.link}>
                      <button>{el.button}</button>
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  </div>
)

export default WhitepapersBlock
