import React from 'react'

import Seo from '../../components/Seo'
import WhitepapersBlock from '../../components/mainComponents/WhitepapersBlock/WhitepapersBlock'

import contactsImage from '../../assets/images/jpg/seo/Contacts-min.jpg'

const Index = () => {
  return (
    <>
      <Seo
        title="Justice | Whitepapers"
        htmlAttributes={{
          lang: 'en',
        }}
        url="https://www.it-justice.com/"
        description="Contact us via e-mail, phone, social networks or visit our comfortable office in the centre of Taganrog."
        image={contactsImage}
      />
      <div>
        <WhitepapersBlock />
      </div>
    </>
  )
}

export default Index
